<template>
  <v-card style="">
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Solicitação marketing
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="lateralMenuVal === 'solicitation'"
      class="d-flex justify-end"
      style="margin-top: -15px; margin-right: 75px;"
    >
      <h3 class="SolTitle">
        N° SLM: <span style="font-weight: 500; font-size: 14px; color: #9A62FD;">0{{ item.slm }}</span>
      </h3>
    </div>
    <div
      class="mx-7 d-flex mt-12 align-start pb-12"
      style="gap: 10px;"
    >
      <div
        style="width: 30%; gap: 15px"
        class="d-flex flex-column"
      >
        <BadgeDt
          :icon="icons.mdiFileDocumentOutline"
          title="Detalhes"
          subtitle="Defina os assuntos"
          :lateral-menu-val="lateralMenuVal"
          menu-value="details"
          @update-lateral-menu="updateLateralMenu"
        />
        <BadgeDt
          :icon="icons.mdiMessageArrowRightOutline"
          title="Solicitação"
          subtitle="Insira os dados"
          :lateral-menu-val="lateralMenuVal"
          menu-value="solicitation"
          @update-lateral-menu="updateLateralMenu"
        />
      </div>
      <div style="width:70%;">
        <div v-if="lateralMenuVal === 'details'">
          <div
            class="d-flex flex-column mb-6  "
            style="gap: 13px;"
          >
            <h3 class="SolTitle">
              N° SLM: <span style="font-weight: 500; font-size: 14px; color: #9A62FD;">0{{ item.slm }}</span>
            </h3>
            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiInformationOutline }}
              </v-icon>
              <h3 style="font-weight: 400; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Solicitado por</strong> {{ item.created_by_user.name }} <strong style="font-weight: 600; font-size: 14px">no dia</strong> {{ formatDateTime(item.created_at) }}
              </h3>
            </div>
            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiOfficeBuildingMarkerOutline }}
              </v-icon>
              <h3 style="font-weight: 500; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Loja:</strong> {{ item.created_by_user.company.fantasy_name }}
              </h3>
            </div>

            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiDoorClosed }}
              </v-icon>
              <h3 style="font-weight: 500; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Setor:</strong> {{ item.created_by_user.occupation_sector.sector.name }}
              </h3>
            </div>
          </div>
          <v-text-field
            v-model="subject"
            label="Assunto"
            outlined
            readonly
            dense
          />
          <h3 style="font-size: 15px; margin-bottom: 15px; font-weight: 600;">
            Defina um <strong style="color: #C48EFF;">prazo</strong> para a sua solicitação
          </h3>
          <v-text-field
            v-model="requestersExpectedDeliveryDate"
            label="Prazo"
            outlined
            style="max-width: 140px;"
            readonly
            dense
          />
          <v-text-field
            v-if="subject !== 'ASSINATURA DE E-MAIL' && subject !== 'OUTROS'"
            v-model="category"
            readonly
            label="Categoria"
            outlined
            dense
          />
          <v-text-field
            v-if="category === 'OUTROS'"
            v-model="others"
            readonly
            outlined
            dense
            label="Qual?"
          />
        </div>
        <section v-if="lateralMenuVal === 'solicitation'">
          <v-form
            v-if="subject === 'AÇÃO DE VENDAS'"
            ref="form"
          >
            <SalesActionForm
              v-model="salesActionData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'BANNER/MATERIAL DIGITAL'"
            ref="form"
          >
            <BannerCreationOrDigitalMaterialForm
              v-model="bannerCreationOrDigitalMaterialData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE MATERIAL'"
            ref="form"
          >
            <PrintedMaterialForm
              v-model="printedMaterialData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE VÍDEOS OU FOTOS'"
            ref="form"
          >
            <VideoProductionForm
              v-model="videoProductionData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'COMUNICAÇÃO INTERNA'"
            ref="form"
          >
            <InternComunicationForm
              v-model="internComunicationData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'MENSAGEM TEMPLATE'"
            ref="form"
          >
            <TemplateMessageForm
              v-model="templateMessageData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'ASSINATURA DE E-MAIL'"
            ref="form"
          >
            <MailSubscriptionForm
              v-model="mailSubscriptionData"
              :is-just-for-read="true"
            />
          </v-form>
          <span>Inserir anexos:</span>
          <AttachmentsList :solicitation-id="item.id" />
          <Activities
            :solic-data="item"
            :solicitation-id="item.id"
            :history-arr="historyArr"
            @getHistory="getHistory"
          />
        </section>
      </div>
    </div>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiCalendar,
  mdiClose,
  mdiDoorClosed,
  mdiFileDocumentOutline,
  mdiInformationOutline,
  mdiInformationVariant,
  mdiMessageArrowRightOutline,
  mdiOfficeBuildingMarkerOutline,
  mdiPlus,
} from '@mdi/js'
import Activities from '../Activities.vue'
import BadgeDt from '../BadgeDt.vue'
import BannerCreationOrDigitalMaterialForm from '../Forms/BannerCreationOrDigitalMaterialForm.vue'
import InternComunicationForm from '../Forms/InternComunicationForm.vue'
import MailSubscriptionForm from '../Forms/MailSubscriptionForm.vue'
import PrintedMaterialForm from '../Forms/PrintedMaterialForm.vue'
import SalesActionForm from '../Forms/SalesActionForm.vue'
import TemplateMessageForm from '../Forms/TemplateMessageForm.vue'
import VideoProductionForm from '../Forms/VideoProductionForm.vue'
import AttachmentsList from '../attachmentsList.vue'

export default {
  name: 'ModalNewSolicitationMKT',
  components: {
    BadgeDt, SalesActionForm, AttachmentsList, Activities, BannerCreationOrDigitalMaterialForm, MailSubscriptionForm, PrintedMaterialForm, VideoProductionForm, InternComunicationForm, TemplateMessageForm,
  },
  mixins: [messages, formatters],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose, mdiFileDocumentOutline, mdiInformationOutline, mdiOfficeBuildingMarkerOutline, mdiPlus, mdiDoorClosed, mdiCalendar, mdiMessageArrowRightOutline, mdiArrowRight, mdiArrowLeft, mdiInformationVariant,
      },
      isPending: false,
      initialDate: '',
      others: '',
      finalDate: '',
      subject: '',
      category: '',
      lateralMenuVal: 'details',

      historyArr: [],

      salesActionData: {
        actualProblem: '',
        communicationChannel: '',
        additionalInfo: '',
        initialDate: '',
        finalDate: '',
        initialDateFormatted: '',
        finalDateFormatted: '',
      },

      bannerCreationOrDigitalMaterialData: {
        briefing: '',
        materialDissemination: '',
      },

      videoProductionData: {
        briefing: '',
        placeOfUse: '',
        videoFormat: '',
      },

      printedMaterialData: {
        id: '',
        length: '',
        briefing: '',
        width: '',
        height: '',
        localPhoto: '',
        locationOfUse: '',
      },

      internComunicationData: {
        briefing: '',
        materialDissemination: '',
      },

      templateMessageData: {
        briefing: '',
        messageObjective: '',
        targetAudience: '',
      },

      mailSubscriptionData: {
        employeeFullName: '',
        employeeFunction: '',
        employeeSkype: '',
        telephoneExtension: '',
      },

    }
  },
  computed: {
    finalDateFormatted() {
      return this.formatDate(this.finalDate)
    },
    initialDateFormatted() {
      return this.formatDate(this.initialDate)
    },
  },
  watch: {
    finalDate() {
      this.dateFormatted = this.formatDate(this.finalDate)
    },
    initialDate() {
      this.dateFormatted = this.formatDate(this.initialDate)
    },
  },
  created() {
    this.getHistory()

    console.log(this.item)

    this.subject = this.item.subject
    this.category = this.item.category
    this.others = this.item.others
    this.requestersExpectedDeliveryDate = this.formatDate(this.item.requesters_expected_delivery_date)

    this.templateMessageData.briefing = this.item.briefing
    this.templateMessageData.messageObjective = this.item.message_objective
    this.templateMessageData.targetAudience = this.item.message_target_audience

    this.salesActionData.actualProblem = this.item.actual_problem
    this.salesActionData.communicationChannel = this.item.communication_channel
    this.salesActionData.additionalInfo = this.item.additional_info
    this.salesActionData.initialDate = this.item.initial_date
    this.salesActionData.finalDate = this.item.final_date
    this.salesActionData.initialDateFormatted = this.formatDate(this.item.initial_date)
    this.salesActionData.finalDateFormatted = this.formatDate(this.item.final_date)

    this.mailSubscriptionData.employeeFullName = this.item.employee_full_name
    this.mailSubscriptionData.employeeFunction = this.item.employee_function
    this.mailSubscriptionData.employeeSkype = this.item.employee_skype
    this.mailSubscriptionData.telephoneExtension = this.item.telephone_extension

    this.bannerCreationOrDigitalMaterialData.briefing = this.item.briefing
    this.bannerCreationOrDigitalMaterialData.materialDissemination = this.item.location_of_use

    this.internComunicationData.briefing = this.item.briefing
    this.internComunicationData.materialDissemination = this.item.location_of_use

    this.printedMaterialData.length = this.item.length
    this.printedMaterialData.id = this.item.id
    this.printedMaterialData.briefing = this.item.briefing
    this.printedMaterialData.width = this.item.width
    this.printedMaterialData.height = this.item.height
    this.printedMaterialData.localPhoto = this.item.local_photo
    this.printedMaterialData.locationOfUse = this.item.location_of_use

    this.videoProductionData.briefing = this.item.briefing
    this.videoProductionData.placeOfUse = this.item.location_of_use
    this.videoProductionData.videoFormat = this.item.video_format
  },
  methods: {
    handleScroll(id) {
      const modalContent = document.getElementsByClassName('v-dialog')[0]
      const section = document.getElementById(id)

      if (modalContent && id && this.item.status === 'ENVIADA PARA VALIDAÇÃO' && !this.item.has_been_validated) {
        const offset = 0
        const sectionPosition = section.getBoundingClientRect().top + modalContent.scrollTop
        const offsetPosition = sectionPosition - offset

        modalContent.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    },
    async getHistory() {
      try {
        const { data } = await axiosIns.get(`/api/v1/solicitations/marketing_solicitation_history/find_by_solic_id/${this.item.id}`)

        this.historyArr = data.data
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          this.handleScroll('activitiesSection')
        }, 0)
      }
    },
    formatDateTime(input) {
      const date = new Date(input)

      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // getMonth retorna 0-11
      const year = date.getFullYear()

      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    updateLateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
    closeModal() {
      this.$emit('close')
    },
    closeAndRefresh() {
      this.$emit('close')
      this.$emit('get-table-data')
    },
    lateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
  },
}
</script>

<style scoped>
  .SolTitle{
    font-size: 14px;
    font-weight: 600;
  }
</style>
