<template>
  <v-card style="">
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Solicitação marketing
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <h3 style="text-align: center; font-size: 15px; font-weight: 500; margin-top: 25px;">
      👋 Olá! Antes de fazer sua solicitação, por favor, nos diga o que você gostaria que o marketing elaborasse?
    </h3>
    <div
      class="mx-7 d-flex mt-12 align-start pb-12"
      style="gap: 10px;"
    >
      <div
        style="width: 30%; gap: 15px"
        class="d-flex flex-column"
      >
        <BadgeDt
          :icon="icons.mdiFileDocumentOutline"
          title="Detalhes"
          subtitle="Defina os assuntos"
          :lateral-menu-val="lateralMenuVal"
          menu-value="details"
          @update-lateral-menu="updateLateralMenu"
        />
        <BadgeDt
          :icon="icons.mdiMessageArrowRightOutline"
          title="Solicitação"
          subtitle="Insira os dados"
          :lateral-menu-val="lateralMenuVal"
          menu-value="solicitation"
          @update-lateral-menu="updateLateralMenu"
        />
      </div>
      <div style="width:70%;">
        <div v-if="lateralMenuVal === 'details'">
          <v-autocomplete
            v-model="subject"
            :items="listCategoriesItem"
            label="Assunto"
            outlined
            dense
          />
          <v-autocomplete
            v-if="subject !== 'ASSINATURA DE E-MAIL' && subject !== 'OUTROS'"
            v-model="category"
            :disabled="!(!!subject)"
            :items="computedListcategory"
            label="Categoria"
            outlined
            dense
          />
          <v-text-field
            v-if="category === 'OUTROS'"
            v-model="others"
            :rules="[rules.required]"
            outlined
            dense
            label="Qual?"
          />
          <h3 style="font-size: 15px; margin-bottom: 15px; font-weight: 600;">
            Defina um <strong style="color: #9A62FD;">prazo</strong> para a sua solicitação:
          </h3>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="requestersExpectedDeliveryDateFormatted"
                :rules="[rules.required]"
                style="max-width: 140px;"
                label="Prazo"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="requestersExpectedDeliveryDate"
            ></v-date-picker>
          </v-menu>
        </div>
        <section v-if="lateralMenuVal === 'solicitation'">
          <v-form
            v-if="subject === 'AÇÃO DE VENDAS'"
            ref="form"
          >
            <SalesActionForm v-model="salesActionData" />
          </v-form>

          <v-form
            v-if="subject === 'BANNER/MATERIAL DIGITAL'"
            ref="form"
          >
            <BannerCreationOrDigitalMaterialForm v-model="bannerCreationOrDigitalMaterialData" />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE MATERIAL'"
            ref="form"
          >
            <PrintedMaterialForm v-model="printedMaterialData" />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE VÍDEOS OU FOTOS'"
            ref="form"
          >
            <VideoProductionForm v-model="videoProductionData" />
          </v-form>

          <v-form
            v-if="subject === 'COMUNICAÇÃO INTERNA'"
            ref="form"
          >
            <InternComunicationForm v-model="internComunicationData" />
          </v-form>

          <v-form
            v-if="subject === 'MENSAGEM TEMPLATE'"
            ref="form"
          >
            <TemplateMessageForm v-model="templateMessageData" />
          </v-form>

          <v-form
            v-if="subject === 'ASSINATURA DE E-MAIL'"
            ref="form"
          >
            <MailSubscriptionForm v-model="mailSubscriptionData" />
          </v-form>
          <div v-if="!!subject">
            <span>Inserir Anexos</span>
            <v-file-input
              v-model="attachments"
              class="mt-3"
              multiple
              :prepend-inner-icon="icons.mdiPaperclip"
              prepend-icon=""
              label="Inserir anexos"
              small-chips
              clearable
              outlined
              dense
            />
          </div>
        </section>
        <div class="d-flex mt-8">
          <v-spacer v-if="lateralMenuVal === 'details'"></v-spacer>
          <v-btn
            v-if="lateralMenuVal !== 'details'"
            color="white"
            outlined
            @click="updateLateralMenu('details')"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiArrowLeft }}
            </v-icon>
            <span>Anterior</span>
          </v-btn>
          <v-spacer v-if="lateralMenuVal !== 'details'"></v-spacer>
          <v-btn
            v-if="lateralMenuVal === 'details'"
            :disabled="!(!!subject && (subject === 'ASSINATURA DE E-MAIL' || (!!category && (category !== 'OUTROS' || !!others))))"
            color="info"
            @click="updateLateralMenu('solicitation')"
          >
            <span>PRÓXIMO</span>
            <v-icon
              size="20"
              class="ml-2"
            >
              {{ icons.mdiArrowRight }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="lateralMenuVal === 'solicitation'"
            :disabled="isPending"
            color="info"
            @click="handleSolicitate"
          >
            <div v-if="!isPending">
              <span>Solicitar</span>
              <v-icon
                size="20"
                class="ml-2"
              >
                {{ icons.mdiMessageArrowRightOutline }}
              </v-icon>
            </div>
            <v-progress-circular
              v-else
              indeterminate
              color="info"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import axiosFileApi from '@/plugins/axiosFileApi'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiCalendar,
  mdiClose, mdiFileDocumentOutline,
  mdiInformationVariant,
  mdiMessageArrowRightOutline, mdiPaperclip,
} from '@mdi/js'
import BadgeDt from '../BadgeDt.vue'
import BannerCreationOrDigitalMaterialForm from '../Forms/BannerCreationOrDigitalMaterialForm.vue'
import InternComunicationForm from '../Forms/InternComunicationForm.vue'
import MailSubscriptionForm from '../Forms/MailSubscriptionForm.vue'
import PrintedMaterialForm from '../Forms/PrintedMaterialForm.vue'
import SalesActionForm from '../Forms/SalesActionForm.vue'
import TemplateMessageForm from '../Forms/TemplateMessageForm.vue'
import VideoProductionForm from '../Forms/VideoProductionForm.vue'

export default {
  name: 'ModalNewSolicitationMKT',
  components: {
    BadgeDt, SalesActionForm, BannerCreationOrDigitalMaterialForm, MailSubscriptionForm, PrintedMaterialForm, VideoProductionForm, InternComunicationForm, TemplateMessageForm,
  },
  mixins: [messages, formatters],
  data() {
    return {
      icons: {
        mdiClose, mdiFileDocumentOutline, mdiPaperclip, mdiCalendar, mdiMessageArrowRightOutline, mdiArrowRight, mdiArrowLeft, mdiInformationVariant,
      },
      attachments: [],
      isPending: false,
      menu1: false,
      initialDate: '',
      others: '',
      finalDate: '',
      subject: '',
      category: '',
      lateralMenuVal: 'details',

      listCategoriesItem: [
        'AÇÃO DE VENDAS',
        'ASSINATURA DE E-MAIL',
        'BANNER/MATERIAL DIGITAL',
        'COMUNICAÇÃO INTERNA',
        'PRODUÇÃO DE MATERIAL',
        'MENSAGEM TEMPLATE',
        'PRODUÇÃO DE VÍDEOS OU FOTOS',
      ],
      salesAction: [
        'LANÇAMENTOS',
        'PROMOÇÃO',
        'QUEIMA DE ESTOQUE',
        'OUTROS',
      ],
      bannerCreation: [
        'REDES SOCIAIS',
        'OUTROS',
      ],
      printedMaterial: [
        'CAMISA',
        'CUPOM',
        'FACHADA',
        'FLYER',
        'PANFLETO',
        'PLACAS',
        'VOUCHER',
        'OUTROS',
      ],
      videoProduction: [
        'EVENTOS',
        'REDE SOCIAL',
        'TV INTERNA',
        'OUTROS',
      ],
      internComunication: [
        'PLACAS',
        'GRUPO DO WHATSAPP',
        'MURAIS',
        'OUTROS',
      ],
      templateMessage: [
        'DISPAROS BOLD',
        'MENSAGENS PARA USO NO WHATSAPP',
        'OUTROS',
      ],

      requestersExpectedDeliveryDate: '',

      salesActionData: {
        actualProblem: '',
        communicationChannel: '',
        additionalInfo: '',
        initialDate: '',
        finalDate: '',
        initialDateFormatted: '',
        finalDateFormatted: '',
      },

      bannerCreationOrDigitalMaterialData: {
        briefing: '',
        materialDissemination: '',
      },

      videoProductionData: {
        briefing: '',
        placeOfUse: '',
        videoFormat: '',
      },

      printedMaterialData: {
        length: '',
        briefing: '',
        width: '',
        height: '',
        localPhoto: null,
        locationOfUse: '',
      },

      internComunicationData: {
        briefing: '',
        materialDissemination: '',
      },

      templateMessageData: {
        briefing: '',
        messageObjective: '',
        targetAudience: '',
      },

      mailSubscriptionData: {
        employeeFullName: '',
        employeeFunction: '',
        employeeSkype: '',
        telephoneExtension: '',
      },

    }
  },
  computed: {
    finalDateFormatted() {
      return this.formatDate(this.finalDate)
    },
    requestersExpectedDeliveryDateFormatted() {
      return this.formatDate(this.requestersExpectedDeliveryDate)
    },
    initialDateFormatted() {
      return this.formatDate(this.initialDate)
    },
    computedListcategory() {
      const subjectMap = {
        'AÇÃO DE VENDAS': this.salesAction,
        'BANNER/MATERIAL DIGITAL': this.bannerCreation,
        'PRODUÇÃO DE MATERIAL': this.printedMaterial,
        'PRODUÇÃO DE VÍDEOS OU FOTOS': this.videoProduction,
        'COMUNICAÇÃO INTERNA': this.internComunication,
        'MENSAGEM TEMPLATE': this.templateMessage,
      }

      return subjectMap[this.subject] || null
    },
  },
  watch: {
    subject() {
      this.category = ''
    },
    finalDate() {
      this.dateFormatted = this.formatDate(this.finalDate)
    },
    requestersExpectedDeliveryDate() {
      this.requestersExpectedDeliveryDateFormatted = this.formatDate(this.requestersExpectedDeliveryDate)
    },
    initialDate() {
      this.dateFormatted = this.formatDate(this.initialDate)
    },
  },
  methods: {
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    updateLateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
    closeModal() {
      this.$emit('close')
    },
    async handleSolicitate() {
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) {
        return
      }

      const subjectMap = {
        'AÇÃO DE VENDAS': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'AÇÃO DE VENDAS',
            category: this.category,
            others: this.category === 'OUTROS' ? this.others : '',
            additional_info: this.salesActionData.additionalInfo,
            communication_channel: this.salesActionData.communicationChannel,
            actual_problem: this.salesActionData.actualProblem,
            final_date: this.salesActionData.finalDate,
            initial_date: this.salesActionData.initialDate,
            status: 'NÃO INICIADA',
          },
        },
        'BANNER/MATERIAL DIGITAL': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'BANNER/MATERIAL DIGITAL',
            others: this.category === 'OUTROS' ? this.others : '',
            category: this.category,
            briefing: this.bannerCreationOrDigitalMaterialData.briefing,
            location_of_use: this.bannerCreationOrDigitalMaterialData.materialDissemination,
            status: 'NÃO INICIADA',
          },
        },
        'PRODUÇÃO DE MATERIAL': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'PRODUÇÃO DE MATERIAL',
            others: this.category === 'OUTROS' ? this.others : '',
            category: this.category,
            briefing: this.printedMaterialData.briefing,
            length: this.printedMaterialData.length,
            width: this.printedMaterialData.width,
            height: this.printedMaterialData.height,
            location_of_use: this.printedMaterialData.locationOfUse,
            status: 'NÃO INICIADA',
          },
        },
        'PRODUÇÃO DE VÍDEOS OU FOTOS': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'PRODUÇÃO DE VÍDEOS OU FOTOS',
            others: this.category === 'OUTROS' ? this.others : '',
            category: this.category,
            briefing: this.videoProductionData.briefing,
            location_of_use: this.videoProductionData.placeOfUse,
            video_format: this.videoProductionData.videoFormat,
            status: 'NÃO INICIADA',
          },
        },
        'COMUNICAÇÃO INTERNA': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'COMUNICAÇÃO INTERNA',
            others: this.category === 'OUTROS' ? this.others : '',
            category: this.category,
            briefing: this.internComunicationData.briefing,
            location_of_use: this.internComunicationData.materialDissemination,
            status: 'NÃO INICIADA',
          },
        },
        'MENSAGEM TEMPLATE': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'MENSAGEM TEMPLATE',
            category: this.category,
            briefing: this.templateMessageData.briefing,
            message_objective: this.templateMessageData.messageObjective,
            message_target_audience: this.templateMessageData.targetAudience,
            others: this.category === 'OUTROS' ? this.others : '',
            status: 'NÃO INICIADA',
          },
        },
        'ASSINATURA DE E-MAIL': {
          body: {
            requesters_expected_delivery_date: this.requestersExpectedDeliveryDate,
            subject: 'ASSINATURA DE E-MAIL',
            others: this.category === 'OUTROS' ? this.others : '',
            employee_full_name: this.mailSubscriptionData.employeeFullName,
            employee_function: this.mailSubscriptionData.employeeFunction,
            employee_skype: this.mailSubscriptionData.employeeSkype,
            telephone_extension: this.mailSubscriptionData.telephoneExtension,
            status: 'NÃO INICIADA',
          },
        },
      }

      const subjectData = subjectMap[this.subject]

      const { body } = subjectData
      try {
        this.isPending = true

        const { data } = await axiosIns.post('/api/v1/solicitations/marketing/store', body)

        const solId = data.data.id

        if (
          this.attachments
          && this.attachments.length > 0
        ) {
          const formData = new FormData()

          this.attachments.forEach(file => {
            formData.append('files[]', file)
          })
          formData.append('id', solId)
          await axiosFileApi.post(
            '/api/v1/solicitations/marketing_attachment/store',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
        }

        this.closeAndRefresh()

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Solicitação enviada.',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.error('Erro ao cadastrar nova solicitação', error)

        if (error.message !== 'Tipo de arquivo não permitido.') {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: 'Ocorreu um erro ao enviar a solicitação!',
            showConfirmButton: true,
            timer: 3000,
          })
        }
      } finally {
        if (this.isPending) {
          this.isPending = false
          this.$refs.form.reset()
          this.subject = ''
          this.category = ''
          this.others = ''
          this.lateralMenuVal = 'details'
        }
      }
    },
    closeAndRefresh() {
      this.$emit('close')
      this.$emit('get-table-data')
    },
    lateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
  },
}
</script>
